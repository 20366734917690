// import './steps/styles'
// import './steps/polyfill'
// import './steps/error-handling'
// import './steps/expose'
// import './steps/vue'

import DocumentReady from 'document-ready-promise'

export default () => {
  return Promise.all([DocumentReady(), Nurve.ready.promise])
}
